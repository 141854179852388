<script setup lang="ts">
const query = {
  'public_search': true,
  'filter[status_eq]': 'published',
  'sort': '-created_at',
  'page[size]': 5,
}

const { data: vacancies, status } = await fetchVacanciesNew(query)
</script>

<template>
  <BaseCard class="flex flex-col gap-y-4 p-4">
    <BaseHeading
      as="h2"
      class="text-muted-700 dark:text-muted-400"
    >
      Nieuwste vacatures
    </BaseHeading>

    <div v-if="status === 'pending'">
      <BasePlaceload />
    </div>
    <div v-else>
      <ul class="list-disc pl-4">
        <li v-for="vacancy in vacancies" :key="vacancy.id">
          <NuxtLink :to="`/vacancies/${vacancy.id}`" class="text-primary-500 text-sm">
            {{ vacancy.title }}
          </NuxtLink>
        </li>
      </ul>
    </div>
  </BaseCard>
</template>
