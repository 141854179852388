<template>
  <BaseCard class="flex flex-col gap-y-4 p-4">
    <BaseHeading
      as="h2"
      class="text-muted-700 dark:text-muted-400"
    >
      Inschrijven als vrijwilliger of organisatie
    </BaseHeading>

    <BaseButton to="/signup" class="primary w-full">
      💪 Inschrijven als vrijwilliger
    </BaseButton>

    <BaseButton to="/organizations/signup" class="w-full">
      🏩 Inschrijven als organisatie
    </BaseButton>
  </basecard>
</template>
